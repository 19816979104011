<template>
  <svg
    width="111"
    height="22"
    viewBox="0 0 111 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M108.471 15.5938V9.83418C108.471 8.87496 108.001 8.32293 107.183 8.32293C106.366 8.32293 105.896 8.90229 105.896 9.83418V15.5938H103.71V9.83418C103.71 8.88862 103.235 8.32293 102.439 8.32293C101.644 8.32293 101.133 8.89955 101.133 9.86698V15.5938H98.9468V6.54022H101.133V7.27898C101.641 6.65482 102.353 6.39355 103.155 6.39355C104.029 6.39355 104.812 6.6988 105.328 7.42109C105.94 6.73747 106.707 6.39355 107.629 6.39355C110.263 6.39355 110.657 8.37758 110.657 9.55818V15.5911L108.471 15.5938Z"
      :class="white ? 'fill-white' : 'fill-[#080936] dark:fill-white'"
    />
    <path
      d="M92.5536 15.7381C90.2119 15.7381 88.0752 13.8577 88.0752 11.1227C88.0752 8.38767 90.2119 6.42188 92.5536 6.42188C93.699 6.42188 94.6905 6.88341 95.4085 7.49879V6.55863H97.5452V15.6184H95.4085V14.6953C94.7248 15.2082 93.8016 15.7381 92.5536 15.7381ZM92.793 8.38768C91.3059 8.38768 90.2632 9.49879 90.2632 11.1056C90.2632 12.7125 91.3059 13.8235 92.8101 13.8235C94.3145 13.8235 95.3572 12.7125 95.3572 11.1056C95.3572 9.49879 94.2803 8.38768 92.793 8.38768Z"
      :class="white ? 'fill-white' : 'fill-[#080936] dark:fill-white'"
    />
    <path
      d="M82.9676 15.7284C80.153 15.7284 78.335 13.914 78.335 11.0885C78.335 8.26305 80.205 6.39258 82.7792 6.39258C85.3534 6.39258 87.2072 8.14565 87.2072 10.8674C87.2072 11.0748 87.2072 11.386 87.1772 11.7546H80.6307C80.7594 13.0314 81.6299 13.8157 82.9676 13.8157C84.3052 13.8157 84.9085 13.0595 85.2934 12.4671L86.8985 13.4417C86.5383 14.1706 85.4736 15.7284 82.9676 15.7284ZM82.7627 8.21118C81.6325 8.21118 80.811 9.00284 80.6825 10.133H84.9303C84.8156 9.00011 83.9721 8.21118 82.7627 8.21118Z"
      :class="white ? 'fill-white' : 'fill-[#080936] dark:fill-white'"
    />
    <path
      d="M74.1167 15.5945V6.54061H76.3029V7.39599L76.4014 7.25388C76.7511 6.7565 77.2867 6.51601 78.082 6.50781V8.79521H77.5545C76.6883 8.79521 76.3003 9.18601 76.3003 10.066V15.5945H74.1167Z"
      :class="white ? 'fill-white' : 'fill-[#080936] dark:fill-white'"
    />
    <path
      d="M71.7694 15.6791C70.1571 15.6791 69.3756 14.9139 69.3756 13.3371V8.36603H68.52V6.54049H69.3756V5.10303H71.5618V6.54049H72.9665V8.36603H71.5618V12.6866C71.5618 13.419 71.8296 13.7169 72.4909 13.7169H72.9665V15.5671C72.9665 15.5671 72.9474 15.5698 72.9336 15.5725C72.7725 15.6053 72.3925 15.6791 71.7694 15.6791Z"
      :class="white ? 'fill-white' : 'fill-[#080936] dark:fill-white'"
    />
    <path
      d="M64.1273 13.7923C63.2555 13.7923 62.4351 13.382 62.0591 13.1598V15.1256C62.6573 15.4846 63.4095 15.741 64.4009 15.741C66.1444 15.741 67.7855 14.9547 67.7855 12.9205C67.7855 11.0915 66.4864 10.4761 65.6318 10.1171C64.6402 9.72396 64.0249 9.60429 64.0249 9.02309C64.0249 8.35643 64.8453 8.28805 65.2384 8.28805C66.0591 8.28805 66.8453 8.59574 67.4949 8.98891V7.05729C66.794 6.68123 65.9564 6.39062 64.9138 6.39062C63.0504 6.39062 61.8711 7.34789 61.8711 9.04018C61.8711 10.2026 62.4351 11.2282 63.7855 11.741C64.4522 11.9974 65.6318 12.288 65.6318 12.9889C65.6318 13.6214 64.6915 13.7923 64.1273 13.7923Z"
      :class="white ? 'fill-white' : 'fill-[#080936] dark:fill-white'"
    />
    <path
      d="M35.4167 3.98535H33.2305V15.5944H35.4167V3.98535Z"
      :class="white ? 'fill-white' : 'fill-[#080936] dark:fill-white'"
    />
    <path
      d="M57.0884 15.5945V6.54061H59.2748V7.39599L59.373 7.25388C59.7228 6.7565 60.2586 6.51601 61.0537 6.50781V8.79521H60.5264C59.6602 8.79521 59.2719 9.18601 59.2719 10.066V15.5945H57.0884Z"
      :class="white ? 'fill-white' : 'fill-[#080936] dark:fill-white'"
    />
    <path
      d="M50.6894 15.7381C48.3476 15.7381 46.2109 13.8577 46.2109 11.1227C46.2109 8.38767 48.3476 6.42188 50.6894 6.42188C51.8347 6.42188 52.8263 6.88341 53.544 7.49879V6.55863H55.6809V15.6184H53.544V14.6953C52.8605 15.2082 51.9374 15.7381 50.6894 15.7381ZM50.9287 8.38768C49.4416 8.38768 48.3989 9.49879 48.3989 11.1056C48.3989 12.7125 49.4416 13.8235 50.9458 13.8235C52.45 13.8235 53.4929 12.7125 53.4929 11.1056C53.4929 9.49879 52.416 8.38768 50.9287 8.38768Z"
      :class="white ? 'fill-white' : 'fill-[#080936] dark:fill-white'"
    />
    <path
      d="M41.1512 15.7287C38.3368 15.7287 36.5186 13.9199 36.5186 11.0944C36.5186 8.269 38.3886 6.39355 40.9628 6.39355C43.5372 6.39355 45.3908 8.1516 45.3908 10.8733C45.3908 11.0808 45.3908 11.392 45.3608 11.7606H38.8143C38.943 13.0374 39.8134 13.8216 41.1512 13.8216C42.4888 13.8216 43.0921 13.0654 43.477 12.4731L45.0823 13.4476C44.7219 14.1765 43.6572 15.7287 41.1512 15.7287ZM40.9466 8.21713C39.8163 8.21713 38.9946 9.0088 38.8663 10.139H43.1141C42.9994 9.00607 42.1559 8.21713 40.9466 8.21713Z"
      :class="white ? 'fill-white' : 'fill-[#080936] dark:fill-white'"
    />
    <path
      d="M29.6209 15.7394C26.9384 15.7394 24.896 14.0022 24.896 11.0676C24.896 8.13311 26.938 6.39307 29.6209 6.39307C30.3862 6.39307 31.0993 6.54336 31.7444 6.83851V8.95373C31.1296 8.54653 30.4571 8.35524 29.6373 8.35524C28.1316 8.35524 27.0822 9.47022 27.0822 11.0662C27.0822 12.6622 28.1342 13.7744 29.6373 13.7744C30.4571 13.7744 31.1296 13.5832 31.7444 13.176V15.2912C31.0993 15.5863 30.3862 15.7394 29.6209 15.7394Z"
      :class="white ? 'fill-white' : 'fill-[#080936] dark:fill-white'"
    />
    <path
      d="M8.64293 16.452L9.55538 14.3282C7.14122 14.2485 5.15953 12.2927 5.15953 9.76521C5.15953 7.23772 7.24527 5.21145 9.72362 5.21145C12.783 5.21145 14.5801 7.60579 14.5801 10.0642C14.5801 13.6389 11.2456 16.1835 8.64293 16.452L6.28391 21.9355C13.3752 22.4948 19.7451 17.1141 19.7451 10.0642C19.7451 4.48721 15.8084 0.0544504 9.72362 0.0544504C4.35196 -0.0115157 -0.054222 4.34414 0.000504347 9.76521C0.04641 14.3125 3.04169 18.1243 7.45107 19.2261L8.64293 16.452Z"
      class="fill-[#7857FF]"
    />
  </svg>
</template>

<script>
export default {
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
